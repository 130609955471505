import { useRef,useState } from 'react';
import { LegendUpload } from './LegendUpload';
import { Connector } from './Connector';

import "./style.module.css";

export const LegendConnector = (props) => {
    const {UUID,        
        translator,
        legendTemplate,
        options,
        blocks,
        blockLookUp,
        characteristics,
        characteristicLookUp
    } = props;

    const [fileContent, setFileContent] = useState([]);
    const [referenceLegend, setReferenceLegend] = useState<string>(null);
    const [visibleLegendUpload, setLegendUploadVisible] = useState<boolean>(true);
    const [visibleConnector, setConnectorVisible] = useState<boolean>(false);
    const [visibleRules, setRulesVisible] = useState<boolean>(false);
    return (
        <>
            {visibleLegendUpload && <LegendUpload
                UUID={UUID}
                translator={translator}
                legendTemplate={legendTemplate}
                options={options}
                blocks={blocks}
                blockLookUp={blockLookUp}
                characteristics={characteristics}
                characteristicLookUp={characteristicLookUp}
                referenceLegend={referenceLegend}
                setReferenceLegend={setReferenceLegend}
                fileContent={fileContent}
                setFileContent={setFileContent}
                setLegendUploadVisible={setLegendUploadVisible}
                setConnectorVisible={setConnectorVisible}
                />}
            {visibleConnector && <Connector
                UUID={UUID}
                translator={translator}
                legendTemplate={legendTemplate}
                options={options}
                blocks={blocks}
                blockLookUp={blockLookUp}
                characteristics={characteristics}
                characteristicLookUp={characteristicLookUp}
                referenceLegend={referenceLegend}
                setReferenceLegend={setReferenceLegend}
                fileContent={fileContent}
                setFileContent={setFileContent}
                setLegendUploadVisible={setLegendUploadVisible}
                setConnectorVisible={setConnectorVisible}
                />}
        </>
    )
}
